<template>
  <base-form-report>
    <template v-slot:default>
      <div class="flex-header mt-5">
        <!-- Date picker -->
        <v-menu
          v-model="menuDatePicker"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on }">
            <div>
              <v-icon color="#000000">mdi-calendar-month</v-icon>
              <v-btn
                v-model="datePicker"
                v-on="on"
                small
                class="btn--date mr-5"
                >{{ formatMonth(datePicker) }}</v-btn
              >
            </div>
          </template>
          <v-date-picker
            v-model="datePicker"
            :first-day-of-week="0"
            :locale="$i18n.locale"
            scrollable
            type="month"
            @input="menuDatePicker = false"
            @change="fetchData"
          ></v-date-picker>
        </v-menu>
        <!--  -->

        <h6>{{ $t("common.facility") }}</h6>
        <v-select
          :items="facilityList"
          v-model="facilitySelected"
          item-text="name"
          item-value="id"
          @change="fetchData"
          dense
          small
          outlined
          color="var(--text__gray)"
          hide-details
          class="v-select-custom ml-1 facility-list"
        ></v-select>
      </div>
      <v-col  lg="5" class="my-5 pa-0">
        <!-- Total Table -->
        <v-simple-table class="table-custom elevation-1 mb-6">
          <template v-slot:default>
            <thead>
              <tr>
                <th>
                  {{ $t("common.total") }}
                </th>
                <th v-for="(item, index) in hotelTaxReportHeader.values" :key="index"> {{ item.amount + $t("common.yen") }} </th>
                <th>
                  {{ $t("common.total") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ $t("common.total") }}</td>
                <td v-for="(item, index) in hotelTaxReportHeader.values" :key="index"> {{ item.count }} </td>
                <td>{{ hotelTaxReportHeader.total }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <!-- List Data Table -->
        <v-simple-table class="table-custom elevation-1">
          <template v-slot:default>
            <thead>
              <tr>
                <th>
                  {{ $t("common.day") }}
                </th>
                <th v-for="(item, index) in hotelTaxReportHeader.values" :key="index"> {{ item.amount + $t("common.yen") }} </th>
                <th>
                  {{ $t("common.total") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in hotelTaxReportDays" :key="index">
                <td>{{ item.day + $t("common.day") }}</td>
                <td v-for="(el, index) in sortDesc(item.values)" :key="index"> {{ el.count }} </td>
                <td>
                  {{ item.total }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </template>
  </base-form-report>
</template>

<script>
import BaseFormReport from '../../../components/FormReport/BaseFormReport'
import { formatMonth } from '@/constants/functions'
import { HOTEL_TAX_REPORT } from '@/api/graphql/formReport/hotel-tax-report'
import { FACILITY_LIST } from '@/api/graphql/facility-settings'
import { mapMutations } from 'vuex'
import gql from 'graphql-tag'

export default {
  name: 'MonthlyLodgingTaxSchedule',
  data () {
    return {
      // Filter header ---------------------------
      menuDatePicker: false,
      datePicker: new Date().toISOString().substr(0, 10),
      facilityList: [],
      facilitySelected: null,
      hotelTaxReportHeader: {},
      hotelTaxReportDays: [],

      // Data table ------------------------------
      total: 0,
      sumTypeFive: 0,
      sumTypeTwo: 0,
      sumTypeZero: 0,
      listDataTable: [
        { id: 1, dayNum: '1', typeFive: '1', typeTwo: '1', typeZero: '1' },
        { id: 2, dayNum: 2, typeFive: '1', typeTwo: '1', typeZero: '1' },
        { id: '3', dayNum: '3', typeFive: '1', typeTwo: '1', typeZero: '1' },
        { id: '4', dayNum: '4', typeFive: '1', typeTwo: '1', typeZero: '1' },
        { id: '5', dayNum: '5', typeFive: '1', typeTwo: '1', typeZero: '1' },
        { id: '6', dayNum: '6', typeFive: '1', typeTwo: '1', typeZero: '1' },
        { id: '7', dayNum: '7', typeFive: '1', typeTwo: '1', typeZero: '1' },
        { id: '8', dayNum: '8', typeFive: '1', typeTwo: '1', typeZero: '1' },
        { id: '9', dayNum: '9', typeFive: '1', typeTwo: '1', typeZero: '1' }
      ]
    }
  },
  async mounted () {
    await this.getFacilityList()
    this.fetchData()
  },
  methods: {
    ...mapMutations(['setLoadingOverlayShow', 'setLoadingOverlayHide']),
    formatMonth,
    getTotal () {
      if (this.listDataTable.length > 0) {
        this.sumTypeFive = this.listDataTable.reduce((n, { typeFive }) => n + parseFloat(typeFive), 0)
        this.sumTypeTwo = this.listDataTable.reduce((n, { typeTwo }) => n + parseFloat(typeTwo), 0)
        this.sumTypeZero = this.listDataTable.reduce((n, { typeZero }) => n + parseFloat(typeZero), 0)
        this.total = this.sumTypeFive + this.sumTypeTwo + this.sumTypeZero
      }
    },
    async fetchData () {
      const year = new Date(this.datePicker).getFullYear()
      const month = new Date(this.datePicker).getMonth() + 1
      this.setLoadingOverlayShow()
      await this.$apollo
        .query({
          query: gql`${HOTEL_TAX_REPORT}`,
          variables: {
            year,
            month,
            facilityId: parseInt(this.facilitySelected)
          },
          fetchPolicy: 'no-cache'
        })
        .then(data => {
          const valuesHeaderSort = data.data.hotelTaxReport.header
          if (valuesHeaderSort.values.length > 0) {
            valuesHeaderSort.values = valuesHeaderSort.values.slice().sort((a, b) =>
              b.amount - a.amount)
          }
          this.hotelTaxReportHeader = valuesHeaderSort
          this.hotelTaxReportDays = data.data.hotelTaxReport.days
          this.setLoadingOverlayHide()
        })
        .catch(error => {
          this.setLoadingOverlayHide()
          console.error(error)
        })
    },
    sortDesc (arr) {
      if (arr.length > 0) {
        arr = arr.slice().sort((a, b) =>
          b.amount - a.amount)
      }
      return arr
    },
    async getFacilityList () {
      await this.$apollo
        .query({
          query: gql`${FACILITY_LIST}`
        })
        .then(data => {
          this.facilityList = data.data.facilityList
          this.facilitySelected = data.data.facilityList[0].id
        })
        .catch(error => {
          console.error(error)
        })
    }
  },
  components: {
    BaseFormReport
  }
}
</script>

<style scoped lang="scss">
.table-custom.v-data-table {
   table>thead>tr>th, td {
     color: #757575 !important
   }
   th {
      font-size: 14px !important;
   }
   td {
     font-size: 16px !important;
   }
 }
.flex-header {
  color: #000000 !important;
}
h6{
  font-size: 12px !important;
  color: #000000 !important;
  font-weight: 500;
}

</style>
