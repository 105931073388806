import gql from 'graphql-tag'

export const HOTEL_TAX_REPORT = gql`
query (
  $year: Int!
  $month: Int!
  $facilityId: Int!
) {
  hotelTaxReport(facilityId: $facilityId, year: $year, month: $month) {
    header {
      values {
        amount 
        count 
      }
      total 
    }
    days {
      day 
      values {
        amount
        count 
      }
      total 
    }
  }
}`
